@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#landscape-message,
#mobile-message {
  top: 0;
  line-height: 1.8;
  z-index: 9998;
  white-space: initial;
}

#infobulle {
  z-index: 9999;
}

.tv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 1;
}

.bv {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
  z-index: 1;
}

.tv, .bv {
  opacity: 1;
  /*transition: opacity 0.5s ease-out;*/
}

.nh {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background: transparent;*/
  z-index: 1;
  opacity: 1;
  transition: opacity 3s ease-out;
  -webkit-animation: opacity 3s ease-out;
  -moz-animation: opacity 3s ease-out;
  -o-animation: opacity 3s ease-out;
  animation: opacity 3s ease-out;
}

.loader {
  position: absolute;
  top: 47.5%;
  left: 47.5%;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #FFF;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}